import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

const myQuery = graphql`
{
    strapiTermsCondition {
        termsContent
    }
}
`

const TermsConditionArea = () => {
  const {
    strapiTermsCondition: {
      termsContent
    }
  } = useStaticQuery(myQuery)

  return (
    <div className='terms-conditions-area pt-100 pb-70'>
      <div className='container'>
        <div className='single-content'>
          <ReactMarkdown>
            {termsContent}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  )
}

export default TermsConditionArea
